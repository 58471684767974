import MemoryStorage from "@/lib/MemoryStorage";
import store from "@/stores";
import NetworkModule from "@/lib/NetworkModule";

import AuthService from "./backends/auth_service";

////// Auth methods to manage the storage of tokens

const authStorage = {
  storage: null,

  saveToken(remember, token, payload) {
    // success callback, set token and user authenticated, then redirect
    if (remember) {
      // Persistent auth
      this.storage = localStorage;
    } else {
      // Session only auth (clears when browser tab closed)
      this.storage = sessionStorage;
    }

    // Now lets make sure we can write to this storage.
    // This protects for iOS private browsing and other browser implementations
    // of the same functionality.
    try {
      const testKey = "yagro_test";
      this.storage.setItem(testKey, testKey);
      this.storage.removeItem(testKey);
    } catch (e) {
      // If we fail to write to it, we default to and in-memory storage mechanism
      this.storage = MemoryStorage.memoryStorage();
    }

    this.storage.setItem("id_token", token);
    this.storage.setItem("id_payload", JSON.stringify(payload));
  },

  user_authenticated() {
    return (
      store.state.user.authenticated &&
      store.state.user.active_auth_backend != null
    );
  },

  fetchOriginPath() {
    return store.getters['user/get_origin_url'];
  },

  saveOriginPath() {
    if (vm.$router.history.current.path != "/users/login") {
      store.commit("user/SET_ORIGIN_URL", vm.$router.history.current.path);
    }
  },

  clearOriginPath() {
    store.commit("user/CLEAR_ORIGIN_URL");
  },

  clearAuthState() {
    store.commit("user/USER_NOT_AUTHENTICATED");
    store.commit("user/ACTIVE_AUTH_BACKEND", null);
    if (this.storage) {
      this.storage.removeItem('user_group');
    }
  },

  getStorage() {
    // This is to get the correct storage when starting up otherwise the
    // session token will be lost and the user logged out.
    if (!this.storage) {
      if (localStorage.getItem("id_token")) {
        this.storage = localStorage;
        return this.storage;
      }
      if (sessionStorage.getItem("id_token")) {
        this.storage = sessionStorage;
        return this.storage;
      }
    }
    // Storage should usually set by the login mechanism
    return this.storage;
  },

  checkAuth(successCallback, failedCallback) {
    try {
      const storage = this.getStorage();
      if (!storage) {
        this.handleUnauthenticatedUser(failedCallback);
        return;
      }

      const jwt = storage.getItem("id_token");
      const payload = JSON.parse(storage.getItem("id_payload"));
      store.commit("user/ACTIVE_AUTH_BACKEND", storage.getItem("active_auth_backend"));

      if (this.isValidPayload(jwt, payload)) {
        this.setupUserSession(payload);
      } else {
        this.handleUnauthenticatedUser(failedCallback);
        return;
      }
    } catch (err) {
      this.handleUnauthenticatedUser(failedCallback);
      return;
    }

    if (!this.user_authenticated()) {
      this.handleUnauthenticatedUser(failedCallback);
    } else if (successCallback) {
      successCallback();
    }
  },

  getAuthHeader() {
    const storage = this.getStorage();
    const token = storage ? storage.getItem("id_token") : null;
    return token ? `Bearer ${token}` : null;
  },

  handleUnauthenticatedUser(failedCallback) {
    this.saveOriginPath();
    this.clearAuthState();

    const currentPath = vm.$router.currentRoute.path;

    const guestPaths = [
      "/users/login",
      "/public/positions",
      "/session/user/reset-password",
      "/session/user/invitation",
      "/session/user/group-invite",
    ];

    if (!guestPaths.some(guestPath => currentPath.includes(guestPath))) {
      window.vm.navigateTo("/users/login");
    }

    if (failedCallback) {
      failedCallback();
    }
  },

  isValidPayload(jwt, payload) {
    return jwt && payload && payload.group && payload.id && 'is_admin' in payload;
  },

  setupUserSession(payload) {
    this.setupPosthog(payload);
    this.storeUserDetails(payload);
  },

  setupPosthog(payload) {
    const posthog = store.getters["settings/posthog"];
    if (posthog) {
      try {
        new NetworkModule.SingleCancelRequest().get(`${store.state.settings.apiLegacyBase}/api/shared/get-profile`, data => {
          posthog.identify(payload.id, {
            email: payload.email,
            name: `${data.profile.firstname} ${data.profile.lastname}`,
          });
        });
        posthog.capture('Set user properties', { $set: { is_admin: payload.is_admin } });

        if (payload.group) {
          const { uuid, name, type, branding_id } = payload.group;
          posthog.group("usergroup", uuid, {
            name: name,
            type: type,
            branding_id: branding_id,
          });
        }

      } catch (err) {
        console.error(err);
      }
    }
  },

  storeUserDetails(payload) {
    store.commit("user/USER_AUTHENTICATED", payload.group);
    store.commit("user/LANGUAGE", payload.language);
    store.commit("user/FORCE_RESET_PASSWORD", payload.force_reset_password);
    store.commit("user/CURRENT_TERMS_ACCEPTED", payload.current_terms_accepted);
    store.commit("user/RELEASE_NOTES_READ", payload.release_notes_read);
    store.commit("user/USER_EMAIL", payload.user_email);
  },

};

////////// AUTH SERVICE BACKEND

export function fetchBackends() {
  let BACKENDS = {};
  BACKENDS.AuthService = new AuthService(authStorage);
  return BACKENDS;
}

export function login(error_callback, creds, admin = false) {
  const BACKENDS = fetchBackends();
  let network_request = new NetworkModule.SingleCancelRequest();
  BACKENDS.AuthService.login(
    error_callback,
    function(response) {
      if (store.getters["settings/env_name"] == "yag.ro") {
        const posthog = store.getters["settings/posthog"];
        if (posthog) {
          try {
            new NetworkModule.SingleCancelRequest().get(`${store.state.settings.apiLegacyBase}/api/shared/get-profile`, data => {
              posthog.identify(response.data.user.id, {
                email: response.data.user.email,
                name: `${data.profile.firstname}  ${data.profile.lastname}`,
              });
            });
            if (response.data.user.groups.length) {
              posthog.group("usergroup", response.data.user.groups[0].uuid, {
                name: response.data.user.groups[0].name,
                type: response.data.user.groups[0].type,
                branding_id: response.data.user.groups[0].branding_id,
                features: response.data.user.groups[0].features,
              });
              posthog.capture(
                'Set user properties',
                {
                  $set: { isAdmin: response.data.user.permissions.includes("YAGRO_ADMIN") },
                },
              )
            }
          } catch (err) {
            console.error(err);
          }
        }
      }

      store.commit("user/ACTIVE_AUTH_BACKEND", BACKENDS.AuthService);
      if (authStorage.fetchOriginPath()) {
        vm.navigateTo({ path: authStorage.fetchOriginPath() });
        authStorage.clearOriginPath();
      } else {
        vm.navigateTo({ path: "/"})
      }

    },
    creds,
    admin,
  );
}

export function logout(redirect_url) {
  const BACKENDS = fetchBackends();
  if (authStorage.getStorage()) {
    let active_backend = authStorage.getStorage().active_auth_backend;
    if (active_backend) {
      BACKENDS[active_backend].logout(redirect_url);
    }
  } else {
    window.vm.navigateTo("/users/login");
  }
  authStorage.saveOriginPath();
  authStorage.clearAuthState();
}

export function refresh(logoutFunc = null) {
  const BACKENDS = fetchBackends();
  if (authStorage.getStorage()) {
    let active_backend = authStorage.getStorage().active_auth_backend;
    if (active_backend) {
      BACKENDS[active_backend].refresh(logoutFunc);
    }
  }
}

export function refresh_promise() {
  const BACKENDS = fetchBackends();
  if (authStorage.getStorage()) {
    let active_backend = authStorage.getStorage().active_auth_backend;
    if (active_backend) {
      return BACKENDS[active_backend].refresh_promise();
    }
  }

  return Promise.reject();
}

export function checkAuth(sucessCallback, error_callback) {
  authStorage.checkAuth(sucessCallback, error_callback);
}

export function getAuthHeader() {
  return authStorage.getAuthHeader();
}

export function user_authenticated() {
  return store.state.user.authenticated;
}

export function changeLanguage(lang_code) {
  let storage = authStorage.getStorage();
  if (storage) {
    let payload = this.getPayload();
    if (payload) {
      payload["language"] = lang_code;
      storage.setItem("id_payload", JSON.stringify(payload));
    }
  }
}

export function getPayload() {
  let storage = authStorage.getStorage();
  if (storage) {
    let payload = storage.getItem("id_payload");
    if (payload) {
      return JSON.parse(payload);
    }
  }
  return null;
}

export function clearAuthState() {
  let storage = authStorage.getStorage();
  if (storage) {
    storage.removeItem("id_token");
    storage.removeItem("id_payload");
    storage.removeItem("refresh_token");
    storage.removeItem("active_auth_backend");
    storage.removeItem("isAdmin");
  }
  store.commit("user/USER_NOT_AUTHENTICATED");
  store.commit("user/ACTIVE_AUTH_BACKEND", null);
}

export function isAdmin() {
  let storage = authStorage.getStorage();
  if (
    storage &&
    (storage["isAdmin"] === true || storage["isAdmin"] === "true")
  ) {
    return true;
  }
  return false;
}

export function permissions() {
  let storage = authStorage.getStorage();
  if (storage) {
    return storage["permissions"];
  }
}
