/* eslint-disable camelcase */

/*
-------------------------------------------------------------------------------
Analytics
-------------------------------------------------------------------------------
Description
Adds analytics to the app, if the env_name (site) is set to 'yag.ro'.

- Posthog
- Sentry
-------------------------------------------------------------------------------
*/

import store from "@/stores";
import * as Sentry from "@sentry/vue";

const analytics = {
  init() {
    const site = store.getters['settings/env_name'];

    if (site === 'yag.ro') {
      this.initPosthog();
      this.initSentry();
    }
  },

  initPosthog() {
    if (window.posthog) {
      try {
        posthog.init('phc_njOXYNPMICFK8Z9bGGmRJUQIKBXz1RrZROCs3NfFl5g', {
          api_host: 'https://eu.posthog.com',
          capture_pageview: false,
          opt_in_site_apps: true,
        });
        store.commit('settings/SET_POSTHOG_CLIENT', posthog);
      } catch (error) {
        console.warn('Failed to initialize Posthog:', error);
      }
    } else {
      console.warn('Posthog not found. Analytics will be disabled.');
    }
  },

  initSentry() {
    if (Sentry && window.posthog) {
      try {
        Sentry.addIntegration(
          posthog.sentryIntegration({
            organization: 'yagro',
            projectId: '5676129',
          }),
        );
      } catch (error) {
        console.warn('Failed to initialize Sentry:', error);
      }
    }
  },
};

export default analytics;
